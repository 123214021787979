import React, {useState, useEffect} from "react";
import PageTitle from "../PageTitle/PageTitle";
import {OrderItem} from "./Orders";
import Select from "react-select";
import {DateRange} from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as rdrLocales from "react-date-range/dist/locale";
import {v4 as uuid} from "uuid";
import {formatDate} from "../Orders/OrderOutstanding";
import {useDispatch, useSelector} from "react-redux";
import {
    getMainSettingsProject,
    getOrdersFilteredDataThunk,
    resetFilter,
    setOrdersDataThunk
} from "../../store/reducers/MainReducer";
import {withLang} from "../../hoc/withLang";
import {gaEvents} from "../../gaEvents";
import FilterOrders from "./Form/FilterOrders";

const OrdersSearch = (props) => {
    const lang = useSelector(state => state.translation.local);
    //********************************************************************* */
    const dispatch = useDispatch();
    const [visibleClearDate, setVisibleClearDate] = useState(false);
    const [visibleClearDate_production, setVisibleClearDate_production] =
        useState(false);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openDate_productionPicker, setOpenDate_productionPicker] =
        useState(false);
    const toggleOpenDatePicker = () => {
        setOpenDatePicker(!openDatePicker);
    };
    const orders_check_enable = useSelector(state => state.main.orders_check_enable);

    useEffect(() => {
        dispatch(getMainSettingsProject());
    }, [])

    useEffect(() => {
    }, [props.form]);

    const resetFilters = () => {
        dispatch(resetFilter());
        dispatch(setOrdersDataThunk(null));
    };

    const toggleOpenDate_productionPicker = () => {
        setOpenDate_productionPicker(!openDate_productionPicker);
    };

    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection"
        }
    ]);

    const [date_production, setDate_production] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection"
        }
    ]);

    const saveData = () => {
        props.changeFilterValue(
            "date",
            {
                from: formatDate(date[0].startDate),
                to: formatDate(date[0].endDate)
            },
            props.form
        );
        setOpenDatePicker(false);
        setVisibleClearDate(true);
    };

    const clearData = () => {
        props.changeFilterValue(
            "date",
            {
                from: "",
                to: ""
            },
            props.form
        );
        setVisibleClearDate(false);
    };

    const saveData_production = () => {
        props.changeFilterValue(
            "date_production",
            {
                from: formatDate(date_production[0].startDate),
                to: formatDate(date_production[0].endDate)
            },
            props.form
        );
        setOpenDate_productionPicker(false);
        setVisibleClearDate_production(true);
    };

    const clearData_production = () => {
        props.changeFilterValue(
            "date_production",
            {
                from: "",
                to: ""
            },
            props.form
        );
        setVisibleClearDate_production(false);
    };

    const updateStatusForOrder = (order_id, data) => {
        let currentStatusFilter = props.form.status;
        props.updateOrder(order_id, data, currentStatusFilter);
    }
    //************************************************************************************************* */
    const renderOrders = () => {
        if (props.orders && props.orders.length > 0 && Array.isArray(props.orders)) {
            return (
                props.orders
                    .map((e) => {
                        return (
                            <OrderItem
                                order={e}
                                toggleChangeStatus={props.toggleChangeStatus}
                                updateOrder={updateStatusForOrder}
                                status={props.status}
                                changedStatus={props.changedStatus}
                                withStatus={true}
                                withClient={true}
                                roles={props.userRoles}
                                clients={props.clients}
                                orders_check_enable={orders_check_enable}
                                settings_project={props.settings_project}
                                getT={props.getT}
                            />
                        );
                    })
            );
        } else {
            return (
                <tr>
                    <td colSpan={8}>
                        <p className={"text-center"}>{props.getT("Заказы еще не созданы")}</p>
                    </td>
                </tr>
            );
        }
    };

    return (
        <div className="content-wrapper">
            <PageTitle title={"orders_search"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <FilterOrders form={props.form} getT={props.getT} changeFilterValue={props.changeFilterValue}>
                            <>
                                {/* Формат даты по промежутку */}
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        {visibleClearDate && (
                                            <i
                                                style={{marginRight: "10px"}}
                                                onClick={clearData}
                                                class="fas fa-ban "
                                            >
                                                {props.getT("Сбросить")}
                                            </i>
                                        )}

                                        <i
                                            style={{
                                                fontSize: "24px",
                                                color: "red",
                                                cursor: "pointer"
                                            }}
                                            class="fa fa-refresh fa-lg"
                                        ></i>
                                        <label>{props.getT("Дата")}:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={`${props.form?.date?.from || ""} ... ${
                                                props.form?.date?.to || ""
                                            }`}
                                            onClick={toggleOpenDatePicker}
                                        />

                                        {openDatePicker && (
                                            <div style={{zIndex: 20, position: "absolute"}}>
                                                <DateRange
                                                    locale={rdrLocales[lang]}
                                                    editableDateInputs={true}
                                                    onChange={(item) => setDate([item.selection])}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={date}
                                                    onFocus={() => {
                                                        gaEvents.customEvent('form_order_search', {
                                                            field_focus: 'Дата'
                                                        })
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    className="btn-container"
                                                >
                                                    <i
                                                        i
                                                        style={{cursor: "pointer"}}
                                                        onClick={saveData}
                                                        class="fas fa-check-circle"
                                                    >
                                                        {props.getT("Применить")}
                                                    </i>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Конец Формат даты по промежутку */}

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>{props.getT("Статус")}</label>
                                        <select
                                            className="form-control"
                                            value={props.form.status}
                                            defaultValue={props.form.status}
                                            onChange={(event) => {
                                                gaEvents.customEvent('form_order_search', {
                                                    field_focus: 'Статус'
                                                })
                                                props.changeFilterValue(
                                                    "status",
                                                    event.target.value,
                                                    props.form
                                                )
                                            }}

                                        >
                                            <option value={""}>{props.getT("Все")}</option>
                                            {props.status.map((e) => (
                                                <option key={e.id} value={e.id}>
                                                    {props.getT(e.name)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* Формат даты_готовности по промежутку */}
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        {visibleClearDate_production && (
                                            <i
                                                style={{marginRight: "10px", cursor: "pointer"}}
                                                onClick={clearData_production}
                                                class="fas fa-ban "
                                            >
                                                {props.getT("Сбросить")}
                                            </i>
                                        )}
                                        <label>{props.getT("Дата производства")}: </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={`${
                                                props.form?.date_production?.from || ""
                                            } ... ${props.form?.date_production?.to || ""}`}
                                            onClick={toggleOpenDate_productionPicker}
                                            onFocus={() => {
                                                gaEvents.customEvent('form_order_search', {
                                                    field_focus: 'Дата производства'
                                                })
                                            }}
                                        ></input>
                                        {openDate_productionPicker && (
                                            <div style={{zIndex: 20, position: "absolute"}}>
                                                <DateRange
                                                    locale={rdrLocales[lang]}
                                                    // locale={registerLocale("ru")}
                                                    editableDateInputs={true}
                                                    onChange={(item) =>
                                                        setDate_production([item.selection])
                                                    }
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={date_production}

                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    className="btn-container"
                                                >
                                                    <i
                                                        style={{cursor: "pointer"}}
                                                        onClick={saveData_production}
                                                        class="fas fa-check-circle"
                                                    >
                                                        {props.getT("Применить")}
                                                    </i>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Конец Формат даты_готовности по промежутку */}

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>{props.getT("Менеджер")}</label>
                                        <select
                                            className="form-control"
                                            value={props.form.manager}
                                            defaultValue={props.form.manager}
                                            onChange={(event) => {

                                                gaEvents.customEvent('form_order_search', {
                                                    field_focus: 'Менеджер'
                                                })

                                                props.changeFilterValue(
                                                    "manager",
                                                    event.target.value,
                                                    props.form
                                                )
                                            }
                                            }
                                        >
                                            <option value={""}>{props.getT("Все")}</option>
                                            {props.managers.map((e) => (
                                                <option key={uuid()} value={e.id}>
                                                    {e.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>{props.getT("Клиент")}</label>
                                        <Select
                                            options={
                                                props.clients
                                                    ? props.clients.map((e) => {
                                                        return {
                                                            label: e.name + " " + "(" + e.phone + ")",
                                                            value: e.id
                                                        };
                                                    })
                                                    : ""
                                            }
                                            value={props.form.client}
                                            onChange={(selectedOption) => {
                                                gaEvents.customEvent('form_order_search', {
                                                    field_focus: 'Клиент'
                                                })
                                                props.changeFilterValue(
                                                    "client",
                                                    Number(selectedOption.value),
                                                    props.form
                                                )
                                            }
                                            }
                                            placeholder={props.getT("Введите имя или номер телефона")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex justify-content-center">
                                        <h4>
                                            {props.getT(`Найденно заказов`)} : {
                                            props.orders.length === 0 ? 0 : props.orders[0]?.count
                                        }
                                            {" "}
                                            {props.getT(`Всего заказов по поиску`)} : {
                                            props.orders.length === 0
                                                ? ""
                                                : props.orders[0]?.count_all
                                        }
                                        </h4>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button onClick={() => resetFilters()}
                                                className={'btn btn-danger'}>{props.getT("Cбросить фильтра")}</button>
                                    </div>
                                </div>


                                <div className="col-sm-12">
                                    {props.orders[0]?.count_all < 300 && (
                                        <>
                                            <h3>{props.getT("Расширенный поиск")}:</h3>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>{props.getT("Тип материала")}:</label>
                                                        <select
                                                            className="form-control"
                                                            value={props.form.material_type}
                                                            onChange={(event) => {
                                                                gaEvents.customEvent('form_order_search', {
                                                                    field_focus: 'Тип материала'
                                                                })
                                                                props.changeFilterValue(
                                                                    "material_type",
                                                                    event.target.value,
                                                                    props.form
                                                                )
                                                            }}
                                                        >
                                                            <option value={""}>{props.getT("Все")}</option>
                                                            {props.materials &&
                                                                props.materials?.map((e) => {
                                                                    return <option key={uuid()}
                                                                                   value={e.material_type_id}>
                                                                        {e.name[props.lang]}
                                                                    </option>
                                                                })}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>{props.getT("Тип услуг")}:</label>
                                                        <select
                                                            className="form-control"
                                                            value={props.form.services_type}
                                                            onChange={(event) => {
                                                                gaEvents.customEvent('form_order_search', {
                                                                    field_focus: 'Тип услуг'
                                                                })
                                                                props.changeFilterValue(
                                                                    "service_type",
                                                                    event.target.value,
                                                                    props.form
                                                                )
                                                            }}
                                                        >
                                                            <option value={""}>{props.getT("Все")}</option>
                                                            {props.services &&
                                                                props.services?.map((e) => (
                                                                    <option value={e.goods_service_type_id}>
                                                                        {e.name[props.lang]}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>{props.getT("Сумма заказа от")}:</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder={props.getT("Сумма заказа от")}
                                                            value={props.form.summ_order}
                                                            onChange={(event) =>
                                                                props.changeFilterValue(
                                                                    "summ_order",
                                                                    event.target.value,
                                                                    props.form
                                                                )
                                                            }
                                                            onFocus={() => {
                                                                gaEvents.customEvent('form_order_search', {
                                                                    field_focus: 'Сумма заказа от'
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>{props.getT("Очередь производства")} </label>
                                                        <select
                                                            className="form-control"
                                                            value={props.form.production_stage_number}
                                                            defaultValue={props.form.production_stage_number}
                                                            onChange={(event) => {
                                                                gaEvents.customEvent('form_order_search', {
                                                                    field_focus: 'Очередь производства'
                                                                })
                                                                props.changeFilterValue(
                                                                    "production_stage_number",
                                                                    event.target.value,
                                                                    props.form
                                                                )
                                                            }
                                                            }
                                                        >
                                                            <option value={""}>{props.getT("Все")}</option>
                                                            <option value="yes">{props.getT("Да")}</option>
                                                            <option value="no">{props.getT("Нет")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>{props.getT("Заказ просрочен")}</label>
                                                        <select
                                                            className="form-control"
                                                            value={props.form.order_out_of_date}
                                                            defaultValue={props.form.order_out_of_date}
                                                            onChange={(event) => {
                                                                gaEvents.customEvent('form_order_search', {
                                                                    field_focus: 'Заказ просрочен'
                                                                })
                                                                props.changeFilterValue(
                                                                    "order_out_of_date",
                                                                    event.target.value,
                                                                    props.form
                                                                )
                                                            }}
                                                        >
                                                            <option value={""}>{props.getT("Все")}</option>
                                                            <option value="yes">{props.getT("Да")}</option>
                                                            <option value="no">{props.getT("Нет")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>{props.getT("Сырье собрано")}</label>
                                                        <select
                                                            className="form-control"
                                                            value={props.form.collected}
                                                            defaultValue={props.form.collected}
                                                            onChange={(event) => {
                                                                gaEvents.customEvent('form_order_search', {
                                                                    field_focus: 'Сырье собрано'
                                                                })
                                                                props.changeFilterValue(
                                                                    "collected",
                                                                    event.target.value,
                                                                    props.form
                                                                )
                                                            }
                                                            }
                                                        >
                                                            <option value={""}>{props.getT("Все")}</option>
                                                            <option value="yes">{props.getT("Да")}</option>
                                                            <option value="no">{props.getT("Нет")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {props.orders[0]?.count_all <= 50 && (
                                                    <div className="col-sm-2">
                                                        <div className="form-group">
                                                            <label>{props.getT("Ошибки 1С")}</label>
                                                            <select
                                                                className="form-control"
                                                                value={props.form.error1c}
                                                                defaultValue={props.form.error1c}
                                                                onChange={(event) => {
                                                                    gaEvents.customEvent('form_order_search', {
                                                                        field_focus: 'Ошибки 1С'
                                                                    })
                                                                    props.changeFilterValue(
                                                                        "error1c",
                                                                        event.target.value,
                                                                        props.form
                                                                    )
                                                                }
                                                                }
                                                            >
                                                                <option value={""}>{props.getT("Все")}</option>
                                                                <option value="yes">{props.getT("Да")}</option>
                                                                <option value="no">{props.getT("Нет")}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>

                        </FilterOrders>

                    </div>
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table m-0 table-striped table-hover orders-table-main">
                                    <thead>
                                    <tr>
                                        {/*<th>{props.getT("Код 1С")}</th>*/}
                                        <th>{props.getT("Тех. ID")}</th>
                                        <th>{props.getT("Название")}</th>
                                        <th>{props.getT("Клиент")}</th>
                                        <th>{props.getT("Менеджер")}</th>
                                        <th>{props.getT("Статус")}</th>
                                        <th>{props.getT("Дата изменения")}</th>
                                        <th>{props.getT("Дата производства")}</th>
                                        <th>{props.getT("Действия")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderOrders()}
                                    <tr>
                                        <td className={"text-center"} colSpan={8}>
                                            {props.getT("Воспользуйтесь фильтрами для поиска необходимого заказа")}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default withLang(OrdersSearch);
